@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");

.scanner-wrapper {
  font-family: "Playfair Display", serif;
  max-width: 500px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.status {
  text-align: center;
  font-size: 12px;
  /* min-height: 18px; */
  font-weight: 100;
}
.guest-name {
  font-family: "Playfair Display", serif;
  letter-spacing: 1px;
  font-size: 12px;
  width: 90%;
  display: block;
  /* min-height: 10vh; */
  margin-left: auto;
  margin-right: auto;
  border: 0px;
  text-align: center;
}

.guest-name:focus {
  outline: 0;
}

.save-wrapper {
  bottom: 10;
  position: sticky;
  display: block;
  margin-top: 20px;
}

.save-button {
  background-image: linear-gradient(
    to right,
    #02aab0 0%,
    #00cdac 51%,
    #02aab0 100%
  );
}
.save-button {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  /* margin: 10px; */
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: none;
  border-radius: 10px;
  /* display: block; */
}

.save-button:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.save-button:focus {
  outline: 0;
}

.save-button:disabled {
  background: #dddddd;
}

.delete-button {
  background-image: linear-gradient(
    to right,
    #d31027 0%,
    #ea384d 51%,
    #d31027 100%
  );
}
.delete-button {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  /* margin: 10px; */
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: none;
  border-radius: 10px;
  display: block;
}

.delete-button:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.delete-button:focus {
  outline: 0;
}

.qr-wrapper {
  display: block;
}

.qr-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  text-align: center;
}

.qr-item {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  padding: 20px 20px 10px 20px;
}

@media only screen and (max-width: 768px) {
  .wrapper {
    max-width: 100vw;
  }
}

.qr-reader {
  margin-top:-80px;
  margin-bottom: -80px;
  margin-left: auto;
  margin-right: auto;
}

.layarsapa-wrapper {
  width: 100vw;
  height: 100vh;
  /* background-color: #121212; */
  background: linear-gradient(
      0deg,
      rgb(0, 0, 0, 0.7),
        rgb(0, 0, 0, 0.7)
    );
  color: antiquewhite;
  text-align: center;
  position: relative;
}

.layarsapa-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}

.layarsapa-wrapper h1 {
  font-size: 80px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.layarsapa-wrapper h2 {
  font-size: 50px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.layarsapa-footer {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  position: fixed;
  bottom: 0;
  color: antiquewhite;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
  letter-spacing: 2px;
  font-weight: 100;
}

.layarsapa-title {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  position: fixed;
  top: 0;
  color: antiquewhite;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
  letter-spacing: 2px;
  z-index: 1000;
}

.layarsapa-title h1, h3 {
  line-height: 0px;
  font-weight: 100;
}

.layarsapa-title h1 {
  font-family: "Playfair Display", serif;
  font-size: 3em;
}
#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -10000;
}

#elem {
  opacity: 0;
}
#elem.show {
  animation: anime 7s 1;
}

@keyframes anime {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#thisButton {background-image: linear-gradient(to right, #AA076B 0%, #61045F  51%, #AA076B  100%)}
#thisButton {
   margin-left: auto;
   margin-right: auto;
   padding: 15px 45px;
   text-align: center;
   text-transform: uppercase;
   transition: 0.5s;
   background-size: 200% auto;
   color: white;            
   /* box-shadow: 0 0 20px #eee; */
   border-radius: 10px;
   display: block;
   border: 0px;
 }

 #thisButton:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }



@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200&display=swap");

.covid-modal {
  font-family: "Raleway", sans-serif;
  font-weight: 200;
  line-height: 1.2em;
  background-color: #fff;
  color: #121212;
  width: 50%;
  border: none;
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
  padding: 20px;
  border-radius: 10px;
}

.covid-modal h2 {
  text-align: center;
  color: #121212;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  /* font-weight: 500; */
  font-size: 2em;
  margin-top: 20px;
}

.covid-button {
  cursor: pointer;
  width: fit-content;
  display: block;
  background-color: burlywood;
  padding: 20px;
  text-align: center;
  background-image: linear-gradient(
    to right,
    #4776e6 0%,
    #8e54e9 51%,
    #4776e6 100%
  );
}

.covid-content {
  margin: 1rem 0 2rem 0;
  padding: 10px 20px;
  white-space: pre-wrap;
  font-size: 22px;
  line-height: 30px;
}

.covid-content p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.covid-content ol {
  list-style-type: decimal;
  padding-left: 1em;
}

.covid-button {
  /* margin: 10px; */
  margin-left: auto;
  margin-right: auto;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.covid-button:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  .covid-modal {
    width: 90%;
    margin-top: 20px;
  }

  .covid-content {
    max-height: 50vh;
    margin-bottom: 20px;
    margin-top: 20px;
    overflow-x: scroll;
    font-size: 14px;
    line-height: 1.9em;
  }
}

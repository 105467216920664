@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.covid-modal {
  font-family: "Raleway", sans-serif;
  font-weight: 200;
  line-height: 1.2em;
  background-color: #fff;
  color: #121212;
  width: 50%;
  border: none;
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
  padding: 20px;
  border-radius: 10px;
}

.covid-modal h2 {
  text-align: center;
  color: #121212;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  /* font-weight: 500; */
  font-size: 2em;
  margin-top: 20px;
}

.covid-button {
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: block;
  background-color: burlywood;
  padding: 20px;
  text-align: center;
  background-image: linear-gradient(
    to right,
    #4776e6 0%,
    #8e54e9 51%,
    #4776e6 100%
  );
}

.covid-content {
  margin: 1rem 0 2rem 0;
  padding: 10px 20px;
  white-space: pre-wrap;
  font-size: 22px;
  line-height: 30px;
}

.covid-content p {
  display: block;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
}

.covid-content ol {
  list-style-type: decimal;
  padding-left: 1em;
}

.covid-button {
  /* margin: 10px; */
  margin-left: auto;
  margin-right: auto;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.covid-button:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  .covid-modal {
    width: 90%;
    margin-top: 20px;
  }

  .covid-content {
    max-height: 50vh;
    margin-bottom: 20px;
    margin-top: 20px;
    overflow-x: scroll;
    font-size: 14px;
    line-height: 1.9em;
  }
}

.scanner-wrapper {
  font-family: "Playfair Display", serif;
  max-width: 500px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.status {
  text-align: center;
  font-size: 12px;
  /* min-height: 18px; */
  font-weight: 100;
}
.guest-name {
  font-family: "Playfair Display", serif;
  letter-spacing: 1px;
  font-size: 12px;
  width: 90%;
  display: block;
  /* min-height: 10vh; */
  margin-left: auto;
  margin-right: auto;
  border: 0px;
  text-align: center;
}

.guest-name:focus {
  outline: 0;
}

.save-wrapper {
  bottom: 10;
  position: -webkit-sticky;
  position: sticky;
  display: block;
  margin-top: 20px;
}

.save-button {
  background-image: linear-gradient(
    to right,
    #02aab0 0%,
    #00cdac 51%,
    #02aab0 100%
  );
}
.save-button {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  /* margin: 10px; */
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: none;
  border-radius: 10px;
  /* display: block; */
}

.save-button:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.save-button:focus {
  outline: 0;
}

.save-button:disabled {
  background: #dddddd;
}

.delete-button {
  background-image: linear-gradient(
    to right,
    #d31027 0%,
    #ea384d 51%,
    #d31027 100%
  );
}
.delete-button {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  /* margin: 10px; */
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: none;
  border-radius: 10px;
  display: block;
}

.delete-button:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.delete-button:focus {
  outline: 0;
}

.qr-wrapper {
  display: block;
}

.qr-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  text-align: center;
}

.qr-item {
  margin-left: auto;
  margin-right: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 20px 20px 10px 20px;
}

@media only screen and (max-width: 768px) {
  .wrapper {
    max-width: 100vw;
  }
}

.qr-reader {
  margin-top:-80px;
  margin-bottom: -80px;
  margin-left: auto;
  margin-right: auto;
}

.layarsapa-wrapper {
  width: 100vw;
  height: 100vh;
  /* background-color: #121212; */
  background: linear-gradient(
      0deg,
      rgb(0, 0, 0, 0.7),
        rgb(0, 0, 0, 0.7)
    );
  color: antiquewhite;
  text-align: center;
  position: relative;
}

.layarsapa-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}

.layarsapa-wrapper h1 {
  font-size: 80px;
  -webkit-margin-before: 0px;
          margin-block-start: 0px;
  -webkit-margin-after: 0px;
          margin-block-end: 0px;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
}

.layarsapa-wrapper h2 {
  font-size: 50px;
  -webkit-margin-before: 0px;
          margin-block-start: 0px;
  -webkit-margin-after: 0px;
          margin-block-end: 0px;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
}

.layarsapa-footer {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  position: fixed;
  bottom: 0;
  color: antiquewhite;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
  letter-spacing: 2px;
  font-weight: 100;
}

.layarsapa-title {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  position: fixed;
  top: 0;
  color: antiquewhite;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
  letter-spacing: 2px;
  z-index: 1000;
}

.layarsapa-title h1, h3 {
  line-height: 0px;
  font-weight: 100;
}

.layarsapa-title h1 {
  font-family: "Playfair Display", serif;
  font-size: 3em;
}
#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -10000;
}

#elem {
  opacity: 0;
}
#elem.show {
  animation: anime 7s 1;
}

@keyframes anime {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#thisButton {background-image: linear-gradient(to right, #AA076B 0%, #61045F  51%, #AA076B  100%)}
#thisButton {
   margin-left: auto;
   margin-right: auto;
   padding: 15px 45px;
   text-align: center;
   text-transform: uppercase;
   transition: 0.5s;
   background-size: 200% auto;
   color: white;            
   /* box-shadow: 0 0 20px #eee; */
   border-radius: 10px;
   display: block;
   border: 0px;
 }

 #thisButton:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }



.pos-r {
  font-family: "Heebo", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 15px;
  line-height: 1.5;
  color: #5f5f5f;
  letter-spacing: 1px;
}

.price-header h2,
h4 {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  margin-bottom: 10px;
  font-weight: 500;
  color: #1c1d3e;
}
#canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
}
.pos-r {
  position: relative;
}
section {
  padding: 80px 0;
}
.section-title {
  margin-bottom: 50px;
  position: relative;
}
.section-title p {
  font-size: 16px;
}
.title-effect {
  width: 50px;
  height: 50px;
  top: 0;
  position: absolute;
  left: 0;
  opacity: 0.5;
  animation: rotation 12.8s steps(1) 0s infinite;
}
.text-center .title-effect {
  left: 50%;
  margin-left: -25px;
}
.text-center .section-title h6 {
  padding: 15px 0;
}

.title-effect .bar {
  background: #2575fc;
}
.title-effect .bar-top {
  width: 100%;
  height: 7px;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: left top;
  transform: scale(0, 1);
  animation: bar-top 3.2s linear 0s infinite;
}
.title-effect .bar-right {
  width: 7px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  transform-origin: left top;
  transform: scale(1, 0);
  animation: bar-right 3.2s linear 0s infinite;
}
.title-effect .bar-bottom {
  width: 100%;
  height: 7px;
  position: absolute;
  right: 0;
  bottom: 0;
  transform-origin: right top;
  transform: scale(0, 1);
  animation: bar-bottom 3.2s linear 0s infinite;
}
.title-effect .bar-left {
  width: 7px;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  transform-origin: left bottom;
  transform: scale(1, 0);
  animation: bar-left 3.2s linear 0s infinite;
}

.title {
  position: relative;
  color: #1c1d3e;
  margin-bottom: 0;
}
.section-title h2 {
  margin-bottom: 15px;
}

/*--rotation--*/

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes bar-top {
  0% {
    transform: scale(0, 1);
  }
  12.5% {
    transform: scale(1, 1);
  }
  87.5% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0, 1);
  }
}
@keyframes bar-right {
  0% {
    transform: scale(1, 0);
  }
  12.5% {
    transform: scale(1, 0);
  }
  25% {
    transform: scale(1, 1);
  }
  75% {
    transform: scale(1, 1);
  }
  87.5% {
    transform: scale(1, 0);
  }
  100% {
    transform: scale(1, 0);
  }
}
@keyframes bar-bottom {
  0% {
    transform: scale(0, 1);
  }
  25% {
    transform: scale(0, 1);
  }
  37.5% {
    transform: scale(1, 1);
  }
  62.5% {
    transform: scale(1, 1);
  }
  75% {
    transform: scale(0, 1);
  }
  100% {
    transform: scale(0, 1);
  }
}
@keyframes bar-left {
  0% {
    transform: scale(1, 0);
  }
  37.5% {
    transform: scale(1, 0);
  }
  50% {
    transform: scale(1, 1);
  }
  62.5% {
    transform: scale(1, 0);
  }
  100% {
    transform: scale(1, 0);
  }
}

/* ------------------------
    Price Table
------------------------*/
.price-table {
  padding: 50px 30px;
  border-radius: 7px;
  overflow: hidden;
  position: relative;
  background: #ffffff;
  text-align: center;
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  transition: all 0.5s ease-in-out 0s;
}
.price-title {
  text-transform: uppercase;
  font-weight: 700;
  /* color: #2575fc; */
  color: #df7171;
}
.price-header {
  position: relative;
  z-index: 9;
  margin-bottom: 50px;
}
.price-value {
  display: inline-block;
  width: 100%;
}
.price-value h2 {
  font-size: 60px;
  line-height: 60px;
  font-weight: 800;
  color: #1c1d3e;
  margin-bottom: 0;
  position: relative;
  display: inline-block;
}
.price-value h2 span {
  font-size: 26px;
  left: -15px;
  line-height: 24px;
  margin: 0;
  position: absolute;
  top: 10px;
  color: #5f5f5f;
  font-weight: normal;
}
.price-value span {
  margin: 15px 0;
  display: block;
}
.price-list {
    margin-top: 50px;
}
.price-list ul li {
  position: relative;
  display: block;
  margin-bottom: 20px;
}
.price-list ul li:last-child {
  margin-bottom: 0;
}
.dark-bg .price-list ul li {
  color: rgba(255, 255, 255, 0.8);
}
.price-list ul li:last-child {
  margin-right: 0;
}
.price-list li i {
  color: #2575fc;
  line-height: 20px;
  font-size: 20px;
}

.price-inside {
  font-size: 80px;
  line-height: 80px;
  position: absolute;
  left: 85%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  font-weight: 900;
  color: rgba(0, 0, 0, 0.04);
}
.price-table::before {
  background: #fafaff;
  content: "";
  height: 300px;
  left: -25%;
  position: absolute;
  top: -10%;
  transform: rotate(-10deg);
  width: 150%;
}
.price-table.active::before {
  transform: rotate(10deg);
}

.price-table.style-2 {
  background: rgba(255, 255, 255, 0.02);
  box-shadow: none;
}
.price-table.style-2::before {
  background: rgba(255, 255, 255, 0.03);
  top: 50%;
  transform: translateY(-50%) rotate(-10deg);
}
.price-table.style-2 .price-title {
  color: #005bea;
}
.price-table.style-3.active .price-title {
  color: #ffffff;
}
.price-table.style-2 .price-value h2,
.price-table.style-3.active .price-value h2 {
  color: #ffffff;
}
.price-table.style-2 .price-list {
  margin-top: 20px;
}
.price-table.style-2.active::before {
  transform: rotate(0);
}
.price-table.style-2 .price-inside {
  color: rgba(255, 255, 255, 0.04);
}
.price-table.style-2 .btn,
.price-table.style-3.active .btn {
  -webkit-box-shadow: 0 10px 20px rgba(255, 255, 255, 0.1);
}

.price-table.active {
  padding: 70px 30px;
}
.price-table.style-3.active {
  background: #2575fc;
  color: #ffffff;
}
.price-table.active .price-value h2 span {
  color: #ffffff;
}
.price-table.style-3:before,
.price-table.style-4:before {
  display: none;
}
.price-table.style-3 .price-list {
  margin-top: 25px;
}

.price-table.style-4 .price-list {
  margin: 25px 0;
}
.price-table.style-4 h3 {
  text-transform: capitalize;
}

.btn {
  padding: 12px 25px;
  font-weight: 500;
  background: none;
  color: #1c1d3e;
  overflow: hidden;
  border-radius: 7px;
  border: none;
  position: relative;
  z-index: 9;
  transition: all 0.5s ease-in-out 0s;
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}
.btn.btn-theme {
  background: rgb(0, 91, 234);
  background: linear-gradient(
    90deg,
    rgba(0, 91, 234, 1) 0%,
    rgba(37, 117, 252, 1) 80%
  );
  color: #ffffff;
}
.btn.focus,
.btn:focus {
  box-shadow: none;
}
.btn:hover {
  transform: translateY(-3px);
}
.btn.btn-circle {
  border-radius: 30px;
}

.btn span {
  display: inline-block;
  opacity: 0;
  transform: translate3d(10, 10px, 0);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.price-table:hover {
  box-shadow: 0px 20px 50px 0px rgba(153, 153, 153, 0.5);
}

.btn::before {
  content: attr(data-text);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.3s cubic-bezier(0.75, 0, 0.125, 1) 0s;
  width: 100%;
  padding: 12px 0;
}
.btn:hover:before {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
}
.btn:hover span {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.btn:hover span:nth-child(1) {
  transition-delay: 0.01s;
}
.btn:hover span:nth-child(2) {
  transition-delay: 0.05s;
}
.btn:hover span:nth-child(3) {
  transition-delay: 0.1s;
}
.btn:hover span:nth-child(4) {
  transition-delay: 0.15s;
}
.btn:hover span:nth-child(5) {
  transition-delay: 0.2s;
}
.btn:hover span:nth-child(6) {
  transition-delay: 0.25s;
}
.btn:hover span:nth-child(7) {
  transition-delay: 0.3s;
}
.btn:hover span:nth-child(8) {
  transition-delay: 0.35s;
}
.btn:hover span:nth-child(9) {
  transition-delay: 0.4s;
}
.btn:hover span:nth-child(10) {
  transition-delay: 0.45s;
}
.btn:hover span:nth-child(11) {
  transition-delay: 0.5s;
}
.btn:hover span:nth-child(12) {
  transition-delay: 0.55s;
}
.btn:hover span:nth-child(13) {
  transition-delay: 0.6s;
}
.btn:hover span:nth-child(14) {
  transition-delay: 0.65s;
}
.btn:hover span:nth-child(15) {
  transition-delay: 0.7s;
}
.btn:hover span:nth-child(16) {
  transition-delay: 0.75s;
}
.btn:hover span:nth-child(17) {
  transition-delay: 0.8s;
}
.btn:hover span:nth-child(18) {
  transition-delay: 0.85s;
}
.btn:hover span:nth-child(19) {
  transition-delay: 0.95s;
}
.btn:hover span:nth-child(20) {
  transition-delay: 1s;
}

